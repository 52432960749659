/* ----------------------------------------------------------- */
/* == track queue module */
/* ----------------------------------------------------------- */

.queue__section {
  margin-bottom: 2rem;
}

.queue__sectionTitle {
  margin: 0;
  margin-bottom: 1rem;
  color: rgba($color-primary, .8);
  text-transform: uppercase;
  letter-spacing: .03rem;
  font-size: 1rem;
  line-height: 1.2em;
}
