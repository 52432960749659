/**
 * Notification module
 * Namespace : .notif
 */

/* ----------------------------------------------------------- */
/* == configuration */
/* ----------------------------------------------------------- */

$notif-info: rgba(#0064da, .15);
$notif-success: rgba(#00da64, .15);
$notif-warning: rgba(#da6900, .15);
$notif-error: rgba(#da0000, .15);

/* ----------------------------------------------------------- */
/* == module */
/* ----------------------------------------------------------- */

.notif {
  margin-top: .5rem;
  padding: .5em 1em;
  border-radius: .3rem;
  font-size: 1.3rem;
}

.notif--info {
  background-color: $notif-info;
  color: #183fc8;
}

.notif--success {
  background-color: $notif-success;
  color: #0f4014;
}

.notif--warning {
  background-color: $notif-warning;
  color: #402c0f;
}

.notif--error {
  background-color: $notif-error;
  color: #9e0000;
}
