/* ----------------------------------------------------------- */
/* == spacings */
/* ----------------------------------------------------------- */

.ma0 {
  margin: 0;
}

.pa0 {
  padding: 0;
}

.ma1 {
  margin: 1rem;
}

.ma2 {
  margin: 2rem;
}

.ma3 {
  margin: 3rem;
}

.pa1 {
  padding: 1rem;
}

.pa2 {
  padding: 2rem;
}

.pa3 {
  padding: 3rem;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.mt5 {
  margin-top: 5rem;
}

.mr0 {
  margin-right: 0;
}

.mr1 {
  margin-right: 1rem;
}

.mr2 {
  margin-right: 2rem;
}

.mr3 {
  margin-right: 3rem;
}

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.mb5 {
  margin-bottom: 5rem;
}

.ml0 {
  margin-left: 0;
}

.ml1 {
  margin-left: 1rem;
}

.ml2 {
  margin-left: 2rem;
}

.ml3 {
  margin-left: 3rem;
}

.pt0 {
  padding-top: 0;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pr0 {
  padding-right: 0;
}

.pr1 {
  padding-right: 1rem;
}

.pr2 {
  padding-right: 2rem;
}

.pr3 {
  padding-right: 3rem;
}

.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pl0 {
  padding-left: 0;
}

.pl1 {
  padding-left: 1rem;
}

.pl2 {
  padding-left: 2rem;
}

.pl3 {
  padding-left: 3rem;
}
