/* ----------------------------------------------------------- */
/* == header */
/* ----------------------------------------------------------- */

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 4.7rem;
  padding-bottom: 5rem;
}

.header__title {
  margin: 0;
  text-align: center;
  font-size: 2rem;
}

.header__titleLink {
  display: inline-block;
  padding: 1rem;
}

/* Side
-------------------------------------------------------------- */

.header__side {
  margin-bottom: -1rem;
  padding-bottom: .7rem;
}

.header__side:first-child {
  margin-left: -1rem;
}

.header__side:last-child {
  margin-right: -1rem;
}

.header__sideBtn,
.header__sideBtn:visited {
  padding: 0;
  padding: 1rem;
  border: none;
  border-radius: .2rem;
  background: none;
  color: $color-primary;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.14em;
  cursor: pointer;
}

.header__sideBtn:hover,
.header__sideBtn:active {
  text-decoration: underline;
}
