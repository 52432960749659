/* ----------------------------------------------------------- */
/* == debug */
/* ----------------------------------------------------------- */

/*
 * Generates a random color
 *
 */

@mixin randomColor {
  $red: round(random()*222);
  $green: round(random()*222);
  $blue: round(random()*222);
}


/*
 * Easily debug an element
 *
 */

@mixin debug() {
  @include randomColor;
  border: .3rem dotted rgb($red, $green, $blue);
  background-color: rgba($red, $green, $blue, 30%);
}


/*
 * Display grid for vertical rhythm
 *
 */

@mixin debug-rythm($bh: $line-height) {
  background: linear-gradient(to bottom, #ba9b9a .1em, transparent .1em ) !important;
  background-size: 100% ($bh + em) !important;
}

.debug-rythm {
  @include debug-rythm();
}
