/* ----------------------------------------------------------- */
/* == typography */
/* ----------------------------------------------------------- */
@use 'sass:math';

html {
  font-size: 62.5%;
  /* IE9-11 calculation fix */
  font-size: calc(1em * .625);
}

body {
  font-size: math.div($base-font, 10) + em;
  line-height: $line-height;
}

p,
ul,
ol,
dl,
blockquote,
pre,
td,
th,
label,
textarea,
caption {
  margin: 0 0 ($line-height + em) 0;
}
