/* ----------------------------------------------------------- */
/* == volume control volume */
/* ----------------------------------------------------------- */

.volume {
  position: relative;
  margin: 0;
}

.playerBig .volume {
  transform: scale(1.11);
  transform-origin: left center;
}

.volume__bg,
.volume__progress {
  position: absolute;
  top: 0;
  left: 0;
  margin: .2rem .1rem;
  width: calc(100% - .2rem);
  height: calc(100% - .4rem);

  clip-path: url(#volume);
}

.volume__bg {
  background: rgba(99, 87, 118, .4);
}

.volume__progress {
  background-color: $color-primary;
}

.volume__input {
  position: relative;
  z-index: 1;
  appearance: none;
  padding: 0;
  width: 4rem;
  border: none;
  border-radius: .1rem;
  background: none;
  box-shadow: none;
}

.volume__input::range-track {
  width: 100%;
  height: 17px;
  border-radius: 0;
  background: transparent;
  box-shadow: 0px 0px 0px #000, 0px 0px 0px #0d0d0d;
  cursor: pointer;

  animate: .2s;
  clip-path: url(#volume);
}

.volume__input::range-thumb {
  position: relative;
  appearance: none;
  width: 20px;
  width: 6px;
  height: 19px;
  border: 0px solid #000;
  border-radius: 2px;
  background: #fff;
  box-shadow: -1px 2px 4px rgba(99, 87, 118, .6);
  cursor: pointer;
  transform: translateY(-.1rem);
}
