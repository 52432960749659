/* ----------------------------------------------------------- */
/* == Big player */
/* ----------------------------------------------------------- */

.playerBig__player {
  position: relative;
  overflow: hidden;
  padding-bottom: calc(100% / (16/9));
  border-radius: .4rem .4rem 0 0;
  background-color: #f2f2f2;
}

.playerBig__player > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.playerBig__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.playerBig__reduce,
.playerBig__reduce:visited {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;
  display: inline-block;
  align-items: center;
  padding: .6rem 1rem;
  border: none;
  border-radius: .2rem;
  background-color: $color-tertiary;
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.27em;
  opacity: .6;
  transition: .15s opacity;
}

.playerBig__reduce.active,
.playerBig__reduce:hover,
.playerBig__reduce:active,
.playerBig__reduce:focus {
  opacity: 1;
}

.playerBig__reduce svg {
  margin-left: .6rem;
}
