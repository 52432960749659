/**
 * Radiobox Module
 * Namespace : .radiobox
 */

/* ----------------------------------------------------------- */
/* == module */
/* ----------------------------------------------------------- */

.radiobox {
  display: inline-flex;
  padding: .2rem;
  max-width: 32rem;
  width: 100%;
  border: $input-border;
  border-radius: $input-border-radius;
  background: $input-background-color;
}

.radiobox--full {
  max-width: 100%;
  width: 100%;
}

.radiobox input {
  position: absolute;
  left: -9999px;
}

.radiobox input + label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  padding: .5rem 1rem;
  min-height: 4rem;
  width: 50%;
  border-radius: $input-border-radius;
  text-align: center;
  white-space: nowrap;
  font-weight: normal;
  line-height: 1.1em;
  transition: all .1s ease-in;
}


/* Checked state */

.radiobox input:checked + label {
  background-color: $color-primary;
  color: #fff;
}

/* Focus state */

.radiobox input:focus + label {
  box-shadow: $focus-ring;
}

.radiobox:active,
.radiobox:focus-within {
  box-shadow: $focus-ring;
}

.radiobox:focus-within input:focus + label {
  box-shadow: none;
}
