/* ----------------------------------------------------------- */
/* == Player progress module */
/* ----------------------------------------------------------- */

.playerBig .playerProgress {
  margin-bottom: 3rem;
}

/* Progress bar
-------------------------------------------------------------- */

.playerProgress__progress {
  position: relative;
}

.playerProgress__progressBg {
  position: absolute;
  width: 100%;
  height: .5rem;
  border-radius: .5rem;
  background-color: #e5e5e5;
}

.playerBig .playerProgress__progressBg {
  border-radius: .2rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.playerProgress__progressBgProgress {
  position: absolute;
  width: 0;
  height: .5rem;
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
  background-color: $color-primary;
}

.playerBig .playerProgress__progressBgProgress {
  border-top-left-radius: 0;
}

.playerProgress__progressInput {
  position: relative;
  z-index: 1;
  display: block;
  appearance: none;
  margin: 0;
  padding: 0;
  max-width: inherit;
  width: 100%;
  height: .5rem;
  border: none;
  border-radius: .5rem;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
}

.playerBig .playerProgress__progressInput {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


.playerProgress__progressInput::range-thumb {
  appearance: none;
  margin-top: -.1rem;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  border-radius: 5rem;
  background: #fff;
  box-shadow: -.1rem .2rem .2rem rgba(99, 87, 118, .4);
  opacity: 0;
  transition: .15s opacity;
}

.playerProgress__progressInput:hover::range-thumb {
  opacity: 1;
}

.playerProgress__progressInput::range-lower {
  height: .5rem;
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
  background-color: $color-primary;
}

.playerBig .playerProgress__progressInput::range-lower {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Timecodes
-------------------------------------------------------------- */

.playerProgress__timecode {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  color: $color-grey-1;
  font-size: 1.2rem;
}

.playerProgress__timecodeItem {
  display: block;
}
