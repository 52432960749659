/* ----------------------------------------------------------- */
/* == colors */
/* ----------------------------------------------------------- */

.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}
