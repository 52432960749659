/* ----------------------------------------------------------- */
/* == main */
/* ----------------------------------------------------------- */

body {
  background-color: $color-bg;
  color: $color-dark-text;
  font-family: $fontstack1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
  max-width: 56rem;
}

/* titles
-------------------------------------------------------------- */

h1 {
  @include flow(50);
}

h2 {
  @include flow(22);
}

h3 {
  @include flow(18);
}

h4 {
  @include flow(16);
}

/* links
-------------------------------------------------------------- */

a,
a:visited {
  color: $color-link;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active,
a:focus,
button:active,
button:focus {
  outline: none;
  box-shadow: $focus-ring;
}

button {
  cursor: pointer;
}

/* lists
-------------------------------------------------------------- */

ul {
  padding-left: 2rem;
  list-style-type: disc;
}

/* divers
-------------------------------------------------------------- */

hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: .1rem;
  border: 0;
  border-top: .1rem solid #ccc;
}
