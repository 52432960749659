/* ----------------------------------------------------------- */
/* == track module */
/* ----------------------------------------------------------- */

.track {
  position: relative;
  display: flex;
  margin-right: -1rem;
  padding: 1rem 0;
  transform-origin: center;
  // animation: trackIn .3s;
}

@keyframes trackIn {
  from {
    opacity: 0;
    transform: scale(.7, 0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.track--active::before {
  position: absolute;
  top: 0;
  top: calc(50% - .5rem);
  left: -2.1rem;
  width: 1rem;
  height: 1rem;
  background: no-repeat url("/src/assets/img/playbar.svg");
  background-position: left center;
  background-size: 1rem 1rem;
  content: "";
}

.track--playing::before {
  background-image: url("/src/assets/img/playbar-animate.svg");
}

.track__main {
  flex-grow: 1;
  min-width: 0;
  cursor: pointer;
}

.track__title {
  overflow: hidden;
  min-height: 1.14em;
  color: $color-secondary;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.14em;
}

.track--active .track__title {
  font-weight: bold;
}

.track__subtitle {
  margin-top: .2rem;
  min-height: 1.5em;
  color: $color-grey-1;
  font-size: 1.2rem;
  line-height: 1.5em;
}

.track__username {
  text-transform: capitalize;
}

.track .popperMenu {
  flex-shrink: 0;
}

.track__menu {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding: 1rem;
  border: none;
  background: none;
  color: $color-primary;
  font-size: 0;
}

.track__menu svg {
  width: 2rem;
}
