/**
 * Table Module
 * namespace : .table
 */


/* ----------------------------------------------------------- */
/* == configuration */
/* ----------------------------------------------------------- */



/* ----------------------------------------------------------- */
/* == module */
/* ----------------------------------------------------------- */

.table {
  max-width: 100%;
  width: 100%;
  border: .1rem solid #cbcbcb;
  border-spacing: 0;
  border-collapse: collapse /* remove spacing between table cells */;
  table-layout: fixed /* http://css-tricks.com/fixing-tables-long-strings */;
}

.table caption {
  padding: 1em 0;
  color: #000;
  text-align: center;
  font: italic 85%/1 arial, sans-serif;
}

.table td,
.table th {
  overflow: hidden;
  margin: 0;
  padding: .5em 1em;
  border-width: 0 0 0 .1rem;
  border-left: .1rem solid #cbcbcb;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: inherit;
}

.table td:first-child,
.table th:first-child {
  border-left-width: 0;
}

.table thead {
  background: #e0e0e0;
  color: #000;
  vertical-align: bottom;
  text-align: left;
}
