/* ----------------------------------------------------------- */
/* == Player cover */
/* ----------------------------------------------------------- */

.playerCover {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  background-color: #f2f2f2;
}

.playerCover::before {
  display: block;
  padding-bottom: 100%;
  content: "";;
}

.playerCover__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.playerCover__expand,
.playerCover__expand:visited {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  padding: .5rem;
  width: 2.4rem;
  height: 2.4rem;
  border: none;
  border-radius: .3rem;
  background: none;
  background-color: rgba($color-primary, .2);
  color: rgba(#fff, .6);
  font-size: 0;
}

.playerCover:hover .playerCover__expand,
.playerCover:active .playerCover__expand,
.playerCover:focus .playerCover__expand {
  background-color: rgba($color-primary, .6);
  color: #fff;
}
